
					@import './src/sass/variables';
					@import './src/sass/mixins';
				























































































































































































.block.recvideo {
	.recording {
		.video-background button {
			background-color: $color__red;
			padding: 20px;

			&:hover {
				background-color: darken($color__red, 5%);
			}

			svg {
				display: none;
			}

			&::before {
				content: '';
				display: block;
				width: 20px;
				height: 20px;
				background-color: $color__white;
			}
		}
	}

	.has-video {
		.video-background-overlay {
			cursor: pointer;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 1;

			&::before {
				content: '';
				position: absolute;
				top: 50%;
				left: 50%;
				z-index: 1;
				border-style: solid;
				border-color: transparent;
				border-width: 30px 40px;
				border-left-color: rgba($color: $color__white, $alpha: 0.3);
				transform: translate(-18%, -50%);
				transition: border-left-color .2s ease;
			}

			&:hover::before {
				border-left-color: rgba($color: $color__white, $alpha: 0.8);
			}
		}
	}

	.video-background {
		position: relative;
		height: 400px;
		background-color: $color__white;
		border-radius: $border_radius;

		video {
			width: 100%;
			height: 100%;
			-webkit-transform: scaleX(-1);
			transform: scaleX(-1);
		}

		button {
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translate(-50%, 50%);
			border-radius: 50%;
			background-color: $color__light_blue;
			color: $color__white;
			padding: 14px;
			border: 3px solid $color__background;
			transition: background-color .2s ease;
			outline: none;
			z-index: 2;

			&:hover {
				background-color: $color__blue;
			}

			svg {
				display: block;
			}
		}
	}

	.meta {
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
	}

	.video-background,
	.meta {
		max-width: 600px;
	}
}
